<template>
    <b-card :title="$t('Add AD')">

        <validation-observer ref="simpleRules">
            <b-row class="match-height">
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('bannerImage')"
                        label-for="bannerImage"
                    >
                        <b-form-file
                            id="bannerImage"
                            accept="image/*"
                            v-model="AdInfo.image"
                            :placeholder="$t('bannerImage')"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('homepageImage')"
                        label-for="homepageImage"
                    >
                        <b-form-file
                            id="homepageImage"
                            accept="image/*"
                            v-model="AdInfo.second_image"
                            :placeholder="$t('homepageImage')"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Title')"
                        label-for="mc-title"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Title')"
                        >
                            <b-form-input
                                id="mc-title"
                                :placeholder="$t('Title')"
                                v-model="AdInfo.title"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Description')"
                        label-for="mc-description"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Description')"
                        >
                            <b-form-input
                                id="mc-description"
                                :placeholder="$t('Description')"
                                v-model="AdInfo.description"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('URL')"
                        label-for="mc-url"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|url"
                            :name="$t('URL')"
                        >
                            <b-form-input
                                id="mc-url"
                                :placeholder="$t('URL')"
                                v-model="AdInfo.url"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="6"
                >
                    <b-form-group
                        :label="$t('Max View Count')"
                        label-for="mc-viewCount"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|integer"
                            :name="$t('Max View Count')"
                        >
                            <b-form-input
                                id="mc-viewCount"
                                :placeholder="$t('Max View Count')"
                                v-model="AdInfo.max_view_count"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                >
                    <b-form-group
                        :label="$t('Start Date')"
                        label-for="mc-startDate"
                    >
                        <b-form-datepicker
                            id="mc-startDate"
                            v-model="AdInfo.start_date"
                            menu-class="w-100"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            calendar-width="100%"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                >
                    <b-form-group
                        :label="$t('End Date')"
                        label-for="mc-endDate"
                    >
                        <b-form-datepicker
                            id="mc-endDate"
                            v-model="AdInfo.end_date"
                            menu-class="w-100"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            calendar-width="100%"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="4"
                >
                    <b-form-group
                        :label="$t('timeRange')"
                        label-for="mc-endDate"
                    >
                        <v-select
                            v-model="AdInfo.times"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            class="select-size-md"
                            label="title"
                            :placeholder="$t('timeRange')"
                            :options="timeRanges"
                        />
                    </b-form-group>

                </b-col>

                <b-col md="12" class="d-flex justify-content-end">
                    <b-button @click="addNewAd" variant="primary" class="">
                        {{ $t("Save") }}
                    </b-button>
                </b-col>
            </b-row>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import {FormWizard} from "vue-form-wizard";


export default {
    name: "add",
    components: {
        BCard, BRow, BCol, BFormGroup, BFormDatepicker,
        FormWizard,
        BButton,
        BFormCheckbox,
        BFormFile,
        BFormInput, vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            AdInfo: {
                title: '',
                description: '',
                url: '',
                image: null,
                second_image: null,
                start_date: '',
                end_date: '',
                max_view_count: '',
                times: [],
            },
            timeRanges: [],
        }
    },
    created() {
        this.$store.dispatch('ads/getTimeRanges')
            .then((res) => {
                this.timeRanges = this.$store.state.ads.timeRanges;
            })
            .catch((err) => {
            });
    },
    methods: {
        addNewAd() {
            this.$refs.simpleRules.validate().then(result => {
                if (result) {
                    // convert time ranges
                    let times = [];
                    for (let time in this.AdInfo.times) {
                        times.push(this.AdInfo.times[time].value)
                    }
                    this.AdInfo.times = times;
                    this.$store.dispatch('ads/addNewAd', this.AdInfo).then(() => {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: this.$t('success'),
                                    icon: 'CheckCircleIcon',
                                    text: "Ad has been added successfully",
                                    variant: 'success',
                                },
                            },
                            {
                                position: 'bottom-right',
                            })
                        setTimeout(function () {
                            this.$router.push({name: 'ads-list'})
                        }, 2000);
                        // this.$router.push('/ads');
                    }).catch(() => {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: this.$t('Error'),
                                    icon: 'InfoIcon',
                                    text: err.response.data.message,
                                    variant: 'danger',
                                },
                            },
                            {
                                position: 'bottom-right',
                            })
                    });
                }
            });
        },
    },
}
</script>

<style scoped>

</style>
